export const meta: BlockMeta = {
  editMode: "both",
  title: "Spotlight",
  description: "",
  keywords: ["spotlight"],
  // @ts-ignore
  icon: "image-filter",
  cache: false,
  tags: ["root"],
  conditions: {
    multiple: true,
  },
}

import { defineBlock } from "eddev/blocks"
import { useAdjustedHeight } from "@hooks/useAdjustedHeight"
import { Eyebrow } from "@components/atoms/Eyebrow"
import { GridLines } from "@components/atoms/GridLines"
import { Button } from "@components/atoms/Button"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { Swiper, SwiperSlide } from "@components/atoms/Swiper"
import { useVideoEmbed } from "@hooks/useVideoEmbed"
import { Icon } from "@components/atoms/Icons"
import { motion } from "framer-motion"
import { useIsMobile } from "@hooks/useIsMobile"
import { useEffect, useState } from "react"
import { Swiper as SwiperType } from "swiper"
import Lottie from "lottie-react"

export default defineBlock("content/spotlight", (props) => {
  const isMobile = useIsMobile()
  const [opacity, setOpacity] = useState(0)
  const [y, setY] = useState(40)
  const { addRef } = useAdjustedHeight()
  const autoplayDelay = 10000
  const videoEmbed = useVideoEmbed()

  useEffect(() => {
    if (isMobile) {
      setOpacity(1)
      setY(0)
    } else {
      setOpacity(0)
      setY(40)
    }
  }, [isMobile])

  const motionVariants = {
    initial: { opacity: opacity, y: y },
    animate: (custom: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        opacity: {
          duration: 0.2,
          delay: custom === 0 ? 0.1 : 0.05 * custom,
          ease: [0, 0, 0, 1],
        },
        y: {
          duration: 0.3,
          delay: custom === 0 ? 0.2 : 0.05 * custom,
          ease: [0.6, 0, 0, 1],
        },
      },
    }),
    hidden: (custom: any) => ({
      opacity: custom === 0 ? opacity : 1,
      y: custom === 0 ? y : 0,
      transition:
        custom === 0
          ? {
              transition: {
                opacity: {
                  duration: 0.2,
                  delay: custom === 0 ? 0.2 : 0.05 * custom,
                  ease: [0, 0, 0, 1],
                },
                y: {
                  duration: 0.3,
                  delay: custom === 0 ? 0.3 : 0.1 * custom,
                  ease: [0.6, 0, 0, 1],
                },
              },
            }
          : {
              duration: 0,
              delay: 0,
            },
    }),
  }

  const motionVariantsAlt = {
    initial: { opacity: 0, y: y },
    animate: (custom: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        opacity: {
          duration: 0.2,
          delay: 0.1 * custom,
          ease: [0, 0, 0, 1],
        },
        y: {
          duration: 0.3,
          delay: 0.2 * custom,
          ease: [0.6, 0, 0, 1],
        },
      },
    }),
    hidden: (custom: any) => ({
      opacity: opacity,
      y: y,
      transition: {
        transition: {
          opacity: {
            duration: 0.3,
            delay: 0.4 * custom,
            ease: [0, 0, 0, 1],
          },
          y: {
            duration: 0.4,
            delay: 0.3 * custom,
            ease: [0.6, 0, 0, 1],
          },
        },
      },
    }),
  }

  function updateBulletProgress(swiper: SwiperType): void {
    const bullets = swiper.pagination.bullets as unknown as HTMLElement[]

    bullets.forEach((bullet, i) => {
      const progressBar = bullet.querySelector(".bullet-progress") as HTMLSpanElement | null

      if (progressBar) {
        progressBar.style.animation = "none" // Reset animation
        progressBar.offsetHeight // Trigger reflow
        progressBar.style.animation = ""
      }

      if (i < swiper.activeIndex) {
        bullet.style.setProperty("background-color", "var(--tw-color-bulletbg)", "important")
      } else {
        bullet.style.setProperty("background-color", "var(--tw-color-bulletbgAlt)", "important")
      }
    })

    // Start animation on the active bullet
    const activeBullet = bullets[swiper.activeIndex] as HTMLElement | undefined
    if (activeBullet) {
      const progressBar = activeBullet.querySelector(".bullet-progress") as HTMLSpanElement | null
      if (progressBar) {
        progressBar.style.animation = `bulletProgress ${autoplayDelay ?? 3000}ms linear forwards`
      }
    }
  }

  return (
    <div
      ref={(el) => (props.gridlines ? el && addRef(el) : undefined)}
      className={`subtheme-${props.scheme} w-full relative bg-bg`}
    >
      {props.gridlines && <GridLines />}
      {props?.rows && (
        <>
          <Swiper
            a11y={{
              enabled: true,
            }}
            keyboard={{
              enabled: true,
            }}
            mousewheel={{
              enabled: true,
              forceToAxis: true,
            }}
            autoplay={{
              delay: autoplayDelay,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              900: {
                slidesPerView: 500,
              },
            }}
            speed={600}
            pagination={{
              enabled: true,
              type: "bullets",
              clickable: true,
              renderBullet: function (index, className) {
                return `<span class="${className}"><span class="bullet-progress"></span></span>`
              },
            }}
            on={{
              init: function (swiper) {
                const bullets = swiper.pagination.bullets

                bullets.forEach((bullet) => {
                  bullet.style.setProperty("background-color", "var(--tw-color-bulletbgAlt)", "important")
                })

                updateBulletProgress(swiper)
              },
              slideChangeTransitionStart: function (swiper) {
                updateBulletProgress(swiper)
              },
              touchEnd: function (swiper) {
                updateBulletProgress(swiper)
              },
            }}
            injectStyles={[
              `
            .swiper-wrapper {
              padding-top: ${props.rows.length > 1 ? `28px` : `0`};
              box-sizing: border-box;
              width: 100%;
            }

            /* Custom Pagination Styles */
            .swiper-pagination {
              top: 0px !important;
              bottom: auto !important;
              display: flex;
              padding: 0 38px;
              gap: 8px !important;
              box-sizing: border-box;
            }

            .swiper-pagination-bullet {
              position: relative;
              flex-grow: 1;
              height: 4px;
              overflow: hidden;
              border-radius: 4px !important;
              margin: 0 !important;
              padding: 0 !important;
              display: flex;
              justify-content: flex-start;
              opacity: 1 !important;
            }

            .swiper-pagination-bullet .bullet-progress {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              background: var(--tw-color-bulletbg);
              transform: scaleX(0);
              transform-origin: left;
            }

            @keyframes bulletProgress {
              from {
                transform: scaleX(0);
              }
              to {
                transform: scaleX(1);
              }
            }

            /* Responsive Styles */
            @media only screen and (min-width: 900px) {
              .swiper-wrapper {
                padding-top: 0;
                flex-direction: column;
              }

              ::slotted(swiper-slide) {
                min-width: 100% !important;
              }
            }

            @media only screen and (min-width: 1200px) {
              .swiper-wrapper {
                margin: auto;
                padding-left: 16px;
                padding-right: 16px;
                max-width: 1280px;
              }
            }

            @media only screen and (min-width: 1600px) {
              .swiper-wrapper {
                max-width: 1440px;
              }
            }
            `,
            ]}
          >
            {props.rows.map((row, i) => (
              //@ts-ignore
              <SwiperSlide key={i} class="px-5 lg:px-0 h-auto">
                <div className="grid-auto min-[1350px]:w-full min-[1350px]:mx-0 items-center gap-0 md:gap-8 pb-8 lg:max-w-[1280px] xl:max-w-[1440px]">
                  {row?.singleDouble ? (
                    <>
                      <motion.div
                        custom={i}
                        variants={row.doubleFields?.flipLayout ? motionVariantsAlt : motionVariants}
                        initial="initial"
                        whileInView={"animate"}
                        viewport={{ once: true, amount: 0.5 }}
                        className={`flex relative ${props.scheme === "light" ? `${props.rows?.length! > 2 && i % 2 && !row.doubleFields?.doubleOverrideBg ? `${row.doubleFields?.largePanel?.textImageLarge ? `bg-text rounded-md` : `md:bg-text md:rounded-md`}` : `${row.doubleFields?.largePanel?.textImageLarge ? `bg-accent rounded-md` : `md:bg-accent md:rounded-md`}`}` : props.scheme === "dark" ? `${row.doubleFields?.largePanel?.textImageLarge ? `bg-text rounded-md` : `md:bg-text md:rounded-md`}` : `${row.doubleFields?.largePanel?.textImageLarge ? `bg-accent rounded-md` : `md:bg-accent md:rounded-md`}`} overflow-hidden col-span-12 md:col-span-7 h-full ${row.doubleFields?.largePanel?.textImageLarge ? `order-1` : `order-2`} ${row.doubleFields?.flipLayout ? `md:order-2` : `md:order-1`}`}
                      >
                        {row.doubleFields?.largePanel?.textImageLarge ? (
                          <div
                            className={`flex items-center justify-center w-full relative overflow-hidden
                              min-h-[calc((100vw-(var(--spacing-5)*2)-(var(--tw-grid-gutter)*2))*(2/3))]
                              md:min-h-[calc(((100vw-(var(--spacing-5)*2)-(var(--tw-grid-gutter)*2)-var(--spacing-8))*(7/12))*(2/3))]
                              lg:min-h-[calc(((1280px-var(--spacing-8))*(7/12))*(2/3))]
                              xl:min-h-[calc(((1440px-var(--spacing-8))*(7/12))*(2/3))]
                            `}
                          >
                            {row.doubleFields.largePanel.media === "video" &&
                              row.doubleFields.largePanel.inlineVideoLg?.mediaItemUrl && (
                                <div className="absolute top-0 left-0 w-full h-full object-cover">
                                  <video
                                    muted
                                    autoPlay
                                    playsInline
                                    loop
                                    className="absolute w-full h-full object-cover"
                                  >
                                    <source
                                      src={row.doubleFields.largePanel.inlineVideoLg.mediaItemUrl}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              )}

                            {row.doubleFields.largePanel.media === "image" && row.doubleFields.largePanel.image && (
                              <ResponsiveImage
                                {...row.doubleFields.largePanel.image}
                                objectFit="cover"
                                objectPosition="center"
                                className="absolute w-full h-full top-0 left-0"
                              />
                            )}

                            {row.doubleFields.largePanel.media === "lottie" &&
                              row.doubleFields.largePanel.lottie?.mediaItemUrl && (
                                <div className="absolute top-0 left-0 w-full h-full object-cover [&>div&>svg]:absolute [&>div&>svg]:w-full [&>div&>svg]:h-full [&>div&>svg]:object-contain [&>*]:absolute [&>*]:top-0 [&>*]:left-0 [&>*]:object-cover [&>*]:w-full [&>*]:h-full">
                                  <LottieLoader mediaItemUrl={row.doubleFields.largePanel.lottie.mediaItemUrl} />
                                </div>
                              )}
                          </div>
                        ) : (
                          <div
                            className={`flex flex-col items-start justify-center ${props.rows?.length! > 1 ? "py-7 md:p-9" : "pt-7 md:p-9"}`}
                          >
                            {row?.doubleFields?.largePanel?.useEyebrowLarge && (
                              <>
                                <div className="hidden md:block">
                                  <Eyebrow
                                    size="m"
                                    style="inverted-primary"
                                    filled
                                    icon={row?.doubleFields?.largePanel?.eyebrowIcon ?? undefined}
                                  >
                                    {row?.doubleFields?.largePanel?.eyebrowText ?? ""}
                                  </Eyebrow>
                                </div>
                                <div className="md:hidden">
                                  <Eyebrow
                                    size="l"
                                    style="primary"
                                    filled
                                    icon={row?.doubleFields?.largePanel?.eyebrowIcon ?? undefined}
                                  >
                                    {row?.doubleFields?.largePanel?.eyebrowText ?? ""}
                                  </Eyebrow>
                                </div>
                              </>
                            )}

                            {row?.doubleFields?.largePanel?.title && (
                              <h3
                                className={`${row.doubleFields?.largePanel?.contentLarge ? `type-title-m !pb-7` : `type-title-m`} text-text md:text-invert pt-5 ${props.rows?.length! > 1 ? "pb-9" : "pb-8"}`}
                              >
                                {row.doubleFields.largePanel.title}
                              </h3>
                            )}

                            {row.doubleFields?.largePanel?.contentLarge && (
                              <p
                                className="type-body-l text-text md:text-invert lg:w-3/4"
                                dangerouslySetInnerHTML={{ __html: row.doubleFields.largePanel.contentLarge }}
                              />
                            )}

                            {row?.doubleFields?.largePanel?.link && (
                              <>
                                <div className="hidden md:block pt-8">
                                  <Button
                                    size="m"
                                    style="invertedAccent"
                                    iconRight="ARROW_RIGHT"
                                    href={row.doubleFields.largePanel.link.url ?? undefined}
                                    target={row.doubleFields.largePanel.link.target ? "_blank" : undefined}
                                  >
                                    {row?.doubleFields?.largePanel?.link.title ?? ""}
                                  </Button>
                                </div>
                                <div className="md:hidden pt-7">
                                  <Button
                                    size="l"
                                    style="primary"
                                    iconRight="ARROW_RIGHT"
                                    href={row.doubleFields.largePanel.link.url ?? undefined}
                                    target={row.doubleFields.largePanel.link.target ? "_blank" : undefined}
                                  >
                                    {row?.doubleFields?.largePanel?.link.title ?? ""}
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </motion.div>
                      <motion.div
                        custom={i}
                        variants={row.doubleFields?.flipLayout ? motionVariants : motionVariantsAlt}
                        initial="initial"
                        whileInView={"animate"}
                        viewport={{ once: true, amount: 0.5 }}
                        className={`flex ${props.scheme === "light" ? `${props.rows?.length! > 2 && i % 2 && !row.doubleFields?.doubleOverrideBg ? `${row.doubleFields?.smallPanel?.textImageSmall ? `bg-text rounded-md` : `md:bg-text md:rounded-md`}` : `${row.doubleFields?.smallPanel?.textImageSmall ? `bg-accent rounded-md` : `md:bg-accent md:rounded-md`}`}` : props.scheme === "dark" ? `${row.doubleFields?.smallPanel?.textImageSmall ? `bg-text rounded-md` : `md:bg-text md:rounded-md`}` : `${row.doubleFields?.smallPanel?.textImageSmall ? `bg-accent rounded-md` : `md:bg-accent md:rounded-md`}`} overflow-hidden col-span-12 md:col-span-5 h-full ${row.doubleFields?.largePanel?.textImageLarge ? `order-2` : `order-1`} ${row.doubleFields?.flipLayout ? `md:order-1` : `md:order-2`}`}
                      >
                        {row.doubleFields?.smallPanel?.textImageSmall ? (
                          <div
                            className={`flex items-center justify-center w-full relative overflow-hidden
                            min-h-[calc((100vw-(var(--spacing-5)*2)-(var(--tw-grid-gutter)*2))*(2/3))]
                            md:min-h-[calc(((100vw-(var(--spacing-5)*2)-(var(--tw-grid-gutter)*2)-var(--spacing-8))*(7/12))*(2/3))]
                            lg:min-h-[calc(((1280px-var(--spacing-8))*(7/12))*(2/3))]
                            xl:min-h-[calc(((1440px-var(--spacing-8))*(7/12))*(2/3))]
                          `}
                          >
                            {row.doubleFields.smallPanel.media === "video" &&
                              row.doubleFields.smallPanel.inlineVideoSm?.mediaItemUrl && (
                                <div className="absolute top-0 left-0 w-full h-full object-cover">
                                  <video
                                    muted
                                    autoPlay
                                    playsInline
                                    loop
                                    className="absolute w-full h-full object-cover"
                                  >
                                    <source
                                      src={row.doubleFields.smallPanel.inlineVideoSm.mediaItemUrl}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              )}

                            {row.doubleFields.smallPanel.media === "image" && row.doubleFields.smallPanel.image && (
                              <ResponsiveImage
                                {...row.doubleFields.smallPanel.image}
                                objectFit="cover"
                                objectPosition="center"
                                className="absolute w-full h-full top-0 left-0"
                              />
                            )}

                            {row.doubleFields.smallPanel.media === "lottie" &&
                              row.doubleFields.smallPanel.lottie?.mediaItemUrl && (
                                <div className="absolute top-0 left-0 w-full h-full object-cover [&>div&>svg]:absolute [&>div&>svg]:w-full [&>div&>svg]:h-full [&>div&>svg]:object-contain [&>*]:absolute [&>*]:top-0 [&>*]:left-0 [&>*]:object-cover [&>*]:w-full [&>*]:h-full">
                                  <LottieLoader mediaItemUrl={row.doubleFields.smallPanel.lottie.mediaItemUrl} />
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className="flex flex-col items-start justify-center py-7 w-full md:p-9">
                            {row?.doubleFields?.smallPanel?.useEyebrowSmall && (
                              <>
                                <div className="hidden md:block">
                                  <Eyebrow
                                    size="m"
                                    style="inverted-primary"
                                    filled
                                    icon={row?.doubleFields?.smallPanel?.eyebrowIcon ?? undefined}
                                  >
                                    {row?.doubleFields?.smallPanel?.eyebrowText ?? ""}
                                  </Eyebrow>
                                </div>
                                <div className="md:hidden">
                                  <Eyebrow
                                    size="l"
                                    style="primary"
                                    filled
                                    icon={row?.doubleFields?.smallPanel?.eyebrowIcon ?? undefined}
                                  >
                                    {row?.doubleFields?.smallPanel?.eyebrowText ?? ""}
                                  </Eyebrow>
                                </div>
                              </>
                            )}
                            <h3 className="type-title-m text-text md:text-invert pt-5 pb-9">
                              {row?.doubleFields?.smallPanel?.title}
                            </h3>

                            {row?.doubleFields?.smallPanel?.link && (
                              <>
                                <div className="hidden md:block">
                                  <Button
                                    size="m"
                                    style="invertedAccent"
                                    iconRight="ARROW_RIGHT"
                                    href={row.doubleFields.smallPanel.link.url ?? undefined}
                                    target={row.doubleFields.smallPanel.link.target ? "_blank" : undefined}
                                  >
                                    {row?.doubleFields?.smallPanel?.link.title ?? ""}
                                  </Button>
                                </div>
                                <div className="md:hidden">
                                  <Button
                                    size="l"
                                    style="primary"
                                    iconRight="ARROW_RIGHT"
                                    href={row.doubleFields.smallPanel.link.url ?? undefined}
                                    target={row.doubleFields.smallPanel.link.target ? "_blank" : undefined}
                                  >
                                    {row?.doubleFields?.smallPanel?.link.title ?? ""}
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </motion.div>
                    </>
                  ) : (
                    <motion.div
                      custom={i}
                      variants={motionVariants}
                      initial="initial"
                      whileInView={"animate"}
                      viewport={{ once: true, amount: 0.5 }}
                      className={`flex ${props.scheme === "light" ? `${row?.singleFields?.overridebg ? `md:bg-text` : `md:bg-accent`}` : props.scheme === "dark" ? `${row?.singleFields?.overridebg ? `md:bg-accent` : `md:bg-text`}` : `${row?.singleFields?.overridebg ? `md:bg-text` : `md:bg-accent`}`} flex-col md:flex-row rounded-md overflow-hidden col-span-12`}
                    >
                      <div
                        className={`flex flex-col items-start justify-center py-7 md:py-9 md:px-[44px] lg:px-[88px] w-full md:w-1/2 order-2 ${row?.singleFields?.singleFlipLayout ? `md:order-2` : `md:order-1`}`}
                      >
                        {row?.singleFields?.useEyebrowSingle && (
                          <>
                            <div className="hidden md:block">
                              <Eyebrow
                                size="m"
                                style="inverted-primary"
                                filled
                                icon={row.singleFields?.eyebrowIcon ?? undefined}
                              >
                                {row.singleFields?.eyebrowText ?? ""}
                              </Eyebrow>
                            </div>
                            <div className="md:hidden">
                              <Eyebrow
                                size="l"
                                style="primary"
                                filled
                                icon={row.singleFields?.eyebrowIcon ?? undefined}
                              >
                                {row.singleFields?.eyebrowText ?? ""}
                              </Eyebrow>
                            </div>
                          </>
                        )}
                        <h3 className="type-title-m text-text md:text-invert py-5">{row?.singleFields?.title}</h3>
                        <p
                          className="type-body-m md:type-body-xl text-text md:text-invert pt-2 pb-7"
                          dangerouslySetInnerHTML={{ __html: row?.singleFields?.content ?? "" }}
                        />

                        {row?.singleFields?.link && (
                          <>
                            <div className="hidden md:block">
                              <Button
                                size="m"
                                style="invertedAccent"
                                iconRight="ARROW_RIGHT"
                                href={row.singleFields.link.url ?? undefined}
                                target={row.singleFields.link.target ? "_blank" : undefined}
                              >
                                {row.singleFields.link.title ?? ""}
                              </Button>
                            </div>
                            <div className="md:hidden">
                              <Button
                                size="l"
                                style="primary"
                                iconRight="ARROW_RIGHT"
                                href={row.singleFields.link.url ?? undefined}
                                target={row.singleFields.link.target ? "_blank" : undefined}
                              >
                                {row.singleFields.link.title ?? ""}
                              </Button>
                            </div>
                          </>
                        )}
                      </div>

                      <div
                        className={`
                            ${props.scheme === "light" ? `bg-accent md:bg-transparent` : props.scheme === "dark" ? `bg-text md:bg-transparent` : `bg-accent md:bg-transparent`}
                            rounded-lg md:rounded-none flex items-center justify-center w-full md:w-1/2 relative overflow-hidden order-1
                            ${row?.singleFields?.singleFlipLayout ? `md:order-1` : `md:order-2`}
                            min-h-[calc((100vw-(var(--spacing-5)*2)-(var(--tw-grid-gutter)*2))*(2/3))]
                            md:min-h-[calc(((100vw-(var(--spacing-5)*2)-(var(--tw-grid-gutter)*2)-var(--spacing-8))*(7/12))*(2/3))]
                            lg:min-h-[calc(((1280px-var(--spacing-8))*(7/12))*(2/3))]
                            xl:min-h-[calc(((1440px-var(--spacing-8))*(7/12))*(2/3))]
                          `}
                      >
                        {row?.singleFields?.media === "embed" && row.singleFields.video && (
                          <div
                            className={`${props.scheme === "light" ? `bg-accent md:bg-transparent` : props.scheme === "dark" ? `bg-text md:bg-transparent` : `bg-accent md:bg-transparent`} rounded-lg md:rounded-none flex items-center justify-center w-full relative h-auto py-11 xl:py-15 order-1 ${row?.singleFields?.singleFlipLayout ? `md:order-1` : `md:order-2`}`}
                          >
                            <button
                              className={`appearance-none w-full h-auto flex ${row?.singleFields?.overridebg ? `md:bg-accent` : `md:bg-stroke`} relative mx-9 md:ml-0 md:mr-[44px] lg:mr-[88px] rounded-md overflow-hidden`}
                              onClick={() => videoEmbed.setVideo(row.singleFields!.video)}
                            >
                              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-invert z-20 border-[2px] border-invert p-4 rounded-full">
                                <Icon name="PLAY" />
                              </div>
                              {row.singleFields.image && (
                                <div className="w-full h-full relative z-10">
                                  <ResponsiveImage {...row.singleFields.image} />
                                </div>
                              )}
                            </button>
                          </div>
                        )}
                        {row?.singleFields?.media === "video" && row.singleFields.videoOptions?.video?.mediaItemUrl && (
                          <div className="absolute top-0 left-0 w-full h-full object-cover">
                            <video
                              controls={row.singleFields.videoOptions?.controls ? true : false}
                              autoPlay={row.singleFields.videoOptions?.autoplay ? true : false}
                              playsInline={row.singleFields.videoOptions?.autoplay ? true : false}
                              muted={row.singleFields.videoOptions?.autoplay ? true : false}
                              loop={row.singleFields.videoOptions?.loop ? true : false}
                              className="absolute w-full h-full object-cover"
                            >
                              <source src={row.singleFields.videoOptions.video.mediaItemUrl} type="video/mp4" />
                            </video>
                          </div>
                        )}
                        {row?.singleFields?.media === "image" && row.singleFields.image && (
                          <ResponsiveImage
                            {...row.singleFields.image}
                            objectFit="cover"
                            objectPosition="center"
                            className="absolute w-full h-full top-0 left-0"
                          />
                        )}
                        {row?.singleFields?.media === "lottie" && row.singleFields.lottie?.mediaItemUrl && (
                          <div className="absolute top-0 left-0 w-full h-full object-cover [&>div&>svg]:absolute [&>div&>svg]:w-full [&>div&>svg]:h-full [&>div&>svg]:object-contain [&>*]:absolute [&>*]:top-0 [&>*]:left-0 [&>*]:object-cover [&>*]:w-full [&>*]:h-full">
                            <LottieLoader mediaItemUrl={row.singleFields.lottie.mediaItemUrl} />
                          </div>
                        )}
                      </div>
                    </motion.div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  )
})

const LottieLoader = ({ mediaItemUrl }: { mediaItemUrl: string }) => {
  const [animationData, setAnimationData] = useState(null)
  useEffect(() => {
    const url = new URL(mediaItemUrl)
    const relativePath = url.pathname
    fetch(relativePath)
      .then((res) => res.json())
      .then((data) => setAnimationData(data))
      .catch((err) => console.error("Failed to load Lottie JSON:", err))
  }, [mediaItemUrl])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData ?? undefined,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return <Lottie {...defaultOptions} />
}
